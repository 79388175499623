<template>
  <div>

    <div v-if="this.selection && this.selection.length !== 0">

      <div class="row item-list"
           v-for="(item, index) in selection">

        <div class="col-12 col-md-2">
          <span>{{itemQty(item)}}</span>
        </div>
        <div class="col-12 col-md-8">
          {{item.name}} - {{item.colour.name}}
        </div>
        <div class="col-12 col-md-1 text-right" >
          <span v-if="can(abilityNames.SEE_PRICE)">$ {{item.price.toFixed(2)}}</span>
        </div>
        <div class="col-12 col-md-1 text-right">
          <span class="action list__img" @click="editAccessory(item, index)">
            <svg-icon name="edit"  />
          </span>
          <span class="action list__img"  @click="openDelModal(index)">
            <svg-icon name="times-circle" />
          </span>
        </div>
      </div>

    </div>

    <p v-else class="text text_green text_italic">
      You have not created any accessory yet. Please click the button to create an accessory.
    </p>

    <div class="thumbnailsTable" v-if="mode=='all'">
        <span class="thumbnailsTable__img" @click="selectNew">
          <svg-icon name="plus-circle" class="large_icon"/>
        </span>
      <span class="thumbnailsTable__text" @click="selectNew">Create New Accessory</span>
    </div>

    <div>
      <template v-if="actionLinksPortalTarget">
          <portal :to="actionLinksPortalTarget">
              <button class="btn btn_transparent" type="button" @click="closeAccessories"><svg-icon name="solid/check" /> Done</button>
          </portal>
      </template>
      <template v-else>
          <button class="btn btn_default" type="button" @click="closeAccessories"><svg-icon name="solid/check" /> Done</button>
      </template>
    </div>

    <transition name="fade">
      <delete @delete="deleteAccessory"
              v-if="show"
              :id="this.accessoryId"
              :package="false"
      />
    </transition>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import { defineAsyncComponent } from 'vue'
  const Delete =  defineAsyncComponent(() => import('@/components/modals/DeleteModal'));
  import * as abilityNames from '@/components/../helpers/abilityNames'

  export default {
    name: "StartScreenAccessories",

    data() {
      return {
        accessoryId: '',
        show: false,
        abilityNames
      }
    },

    props: ['actionLinksPortalTarget'],

    components: {
      Delete
    },

    methods: {

      selectNew(){
        this.$emit('newAccessor');
      },

      closeAccessories(){
          this.$store.commit('orderPosition/optionNext', 'ACC');
//          this.$store.commit('orderPosition/goToStep', 'All Options');
      },

      openDelModal(index) {
        this.show = true;
        this.accessoryId = index;

        this.$store.commit('createdOrder/deleteModal', true);
      },

      deleteAccessory(index)
      {
          this.$store.dispatch('accessories/deleteAccessory', index);
      },

      editAccessory(item, index){
          item.index = index;
          this.$store.commit('accessories/currentItem', item);
          this.$store.commit('accessories/mode', 'edit');
      },
        itemQty(item){
            var res = item.qty;

            if (item.measure_type == 'SQR')
            {
              if(this.isAdmin() || this.isFirstDimensionWidth()) res += ' x ' + item.length1 + 'x' + item.length2 + 'mm'
              else res += ' x ' + item.length2 + 'x' + item.length1 + 'mm'

            }
            else if (item.measure_type == 'LNTH')
            {
                res += ' x (';

                if (item.length1)
                {
                    res += item.length1
                }
                if (item.length2)
                {
                    res += '+' + item.length2
                }
                if (item.length3)
                {
                    res += '+' + item.length3
                }
                if (item.length4)
                {
                    res += '+' + item.length4
                }
                res += ')'
            }

            return res;
        }
    },

    computed: {

          ...mapGetters({
              mode : 'accessories/mode',
              can: 'auth/can',
          }),

        canSubmitOrders(){
            return this.can(abilityNames.SUBMIT_ORDERS)
        },
        canSendQuotes(){
            return this.can(abilityNames.SEND_QUOTES)
        },
        canSendEstimates(){
            return this.can(abilityNames.SEND_ESTIMATES)
        },

        selection() {
          return this.$store.getters['orderPosition/getCurrentOptionSettings'];
      },
    },
    created() {
      this.$store.commit('orderPosition/updateHideBackButtonAtAccessoriesState',true);
    },
    destroyed(){
      this.$store.commit('orderPosition/updateHideBackButtonAtAccessoriesState',false);
    }
  }
</script>

<style scoped>
  .item-list{
    padding: 10px 0px;
  }

  .item-list:nth-child(even){
    background-color: #DDD;
  }

  .action{
    display: inline-block;
  }

  .text-right{
    text-align: right;
  }
</style>
